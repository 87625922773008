// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-certificates-nptel-blockchain-tsx": () => import("./../../../src/pages/certificates/nptel-blockchain.tsx" /* webpackChunkName: "component---src-pages-certificates-nptel-blockchain-tsx" */),
  "component---src-pages-certificates-tcs-codevita-s-8-tsx": () => import("./../../../src/pages/certificates/tcs-codevita-s8.tsx" /* webpackChunkName: "component---src-pages-certificates-tcs-codevita-s-8-tsx" */),
  "component---src-pages-certificates-tcs-codevita-s-9-tsx": () => import("./../../../src/pages/certificates/tcs-codevita-s9.tsx" /* webpackChunkName: "component---src-pages-certificates-tcs-codevita-s-9-tsx" */),
  "component---src-pages-certificates-tcs-internship-tsx": () => import("./../../../src/pages/certificates/tcs-internship.tsx" /* webpackChunkName: "component---src-pages-certificates-tcs-internship-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

